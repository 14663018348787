var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"showBigBox"},[(_vm.isMany)?_c('div',{staticClass:"sourceList"},[_c('vuedraggable',_vm._b({on:{"end":_vm.dragEnd},model:{value:(_vm.manyPath),callback:function ($$v) {_vm.manyPath=$$v},expression:"manyPath"}},'vuedraggable',{
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      scroll: true,
    },false),[_c('transition-group',_vm._l((_vm.manyPath),function(item,index){return _c('div',{key:index,staticClass:"item"},[(_vm.type == 'image' || _vm.type=='live')?_c('div',{style:(_vm.showStyle)},[(item.path)?_c('img',{attrs:{"src":item.path,"alt":""}}):_c('img',{attrs:{"src":item,"alt":""}})]):_vm._e(),(_vm.type == 'video')?_c('div',{style:(_vm.showStyle)},[(item.cover)?_c('img',{attrs:{"src":item.cover,"alt":""}}):_c('video',{attrs:{"src":item.path}})]):_vm._e(),_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.removeThis(index)}}})])}),0)],1),_c('div',{staticClass:"select",style:(_vm.showStyle),on:{"click":function($event){_vm.isOpenUploadSource = true}}},[_c('i',{staticClass:"el-icon-plus"})])],1):_c('div',{staticClass:"onlyOne"},[(_vm.path)?_c('div',{staticClass:"sourceItem",style:(_vm.showStyle)},[(_vm.type == 'image' || _vm.type == 'live')?_c('img',{attrs:{"src":_vm.path,"alt":""}}):_vm._e(),(_vm.type == 'video' || _vm.type == 'liveVideo')?_c('video',{attrs:{"src":_vm.path},on:{"mouseenter":function ($event) {
          $event.target.play();
        },"mouseleave":function ($event) {
          $event.target.pause();
        }}}):_vm._e(),_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.removeThis(null)}}})]):_vm._e(),(!_vm.path)?_c('div',{staticClass:"select",style:(_vm.showStyle),on:{"click":function($event){_vm.isOpenUploadSource = true}}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e()]),(_vm.isOpenUploadSource)?_c('uploadSource',{attrs:{"dialogVisible":_vm.isOpenUploadSource,"url":_vm.url,"manyPath":_vm.manyPath,"isMany":_vm.isMany,"isManyMax":_vm.isManyMax,"maxSize":_vm.maxSize,"liveStatus":_vm.liveStatus,"temporary_type":_vm.temporary_type,"type":_vm.type},on:{"changeStatus":_vm.changeStatus,"getSource":_vm.getSource}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }