<template>
  <div class="showBigBox">
    <!-- 多个素材展示添加 -->
    <div v-if="isMany" class="sourceList">
      <vuedraggable v-model="manyPath" v-bind="{
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        scroll: true,
      }" @end="dragEnd">
        <transition-group>
          <div class="item" v-for="(item, index) in manyPath" :key="index">
            <div :style="showStyle" v-if="type == 'image' || type=='live'">
              <img v-if="item.path" :src="item.path" alt="">
              <img v-else :src="item" alt="">
            </div>
            <div :style="showStyle" v-if="type == 'video'">
              <img v-if="item.cover" :src="item.cover" alt="" />
              <video v-else :src="item.path"></video>
            </div>
            <i class="el-icon-error" @click="removeThis(index)"></i>
          </div>
        </transition-group>
      </vuedraggable>
      <div class="select" :style="showStyle" @click="isOpenUploadSource = true">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <!-- 单个素材展示添加 -->
    <div v-else class="onlyOne">
      <div class="sourceItem" :style="showStyle" v-if="path">
        <img v-if="type == 'image' || type == 'live'" :src="path" alt="" />
        <video v-if="type == 'video' || type == 'liveVideo'" :src="path" @mouseenter="
          $event => {
            $event.target.play();
          }
        " @mouseleave="
          $event => {
            $event.target.pause();
          }
        "></video>

        <i class="el-icon-error" @click="removeThis(null)"></i>
      </div>
      <div class="select" v-if="!path" :style="showStyle" @click="isOpenUploadSource = true">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <uploadSource v-if="isOpenUploadSource" :dialogVisible="isOpenUploadSource" :url="url" :manyPath="manyPath"
      :isMany="isMany" :isManyMax="isManyMax" :maxSize="maxSize" :liveStatus="liveStatus"
      :temporary_type="temporary_type" :type="type" @changeStatus="changeStatus" @getSource="getSource"></uploadSource>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import uploadSource from '@/components/uploadSource';
export default {
  components: {
    uploadSource,
    vuedraggable,
  },
  props: {
    // 是否需要上传多个素材
    isMany: {
      type: Boolean,
      default: false,
    },
    // 单个素材的默认路径
    path: {
      type: String,
      default: '',
    },
    // 多个素材的默认路径
    manyPath: {
      type: Array,
      default: () => [],
    },
    isManyMax: {
      type: Number,
      default: null,
    },
    // 此时素材组件的类型 image -- video
    type: {
      type: String,
      default: 'image',
    },
    // 素材展示的行内样式
    showStyle: {
      type: Object,
      default: () => {
        return {
          width: '110px',
          height: '110px',
        };
      },
    },
    // 是否是微信的临时素材
    temporary_type: {
      type: Number,
      default: 0,
    },
    /**
    * 类别判断
    * 0-站点内正常上传图片
    * 1-站点外上传图片
    * 2-直播上传图片素材
    */
    url: {
      type: Number,
      default: 0,
    },
    maxSize: {
      type: Number,
      default: 0,
    },
    liveStatus: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isOpenUploadSource: false,
    };
  },
  methods: {
    changeStatus (val) {
      this.isOpenUploadSource = val;
    },
    getSource (list) {
      if (this.isMany) {
        this.$emit('getSource', list);
      } else {
        this.$emit('getSource', list[0]);
      }
    },
    removeThis (index) {
      // 多选的情况下需要传递删除的索引
      if (index !== null) {
        this.$emit('removeThis', index);
      } else {
        this.$emit('removeThis');
      }
    },
    // 拖动图片排序
    dragEnd () {
      this.$emit('changeOrder', this.manyPath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-icon-error {
  display: none;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.showBigBox {
  display: flex;

  .onlyOne {
    display: flex;
  }
}

.sourceItem {
  position: relative;
  border: 1px dashed #c0ccda;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-right: 20px;
  }

  &:hover i {
    display: block;
  }
}

.select {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 5px;
  cursor: pointer;
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 28px;
    color: #8c939d;
  }

  &:hover {
    border: 1px dashed #409eff;
  }
}

.sourceList {
  display: flex;
  flex-wrap: wrap;

  &>div {

    &>span {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .item {
    margin: 0 10px 10px 0;
    background-size: cover;
    position: relative;
    border: 1px solid #ccc;

    &:hover>i {
      display: block;
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
